import request from "@/utils/request";

// Crossing
export function crossingList(params) {
  return request({ url: `/crossings/`, method: "get", params });
}

export function crossingCreate(data) {
  return request({ url: `/crossings/`, method: "post", data });
}

export function crossingUpdate(data) {
  return request({ url: `/crossings/${data.id}/`, method: "put", data });
}

export function crossingDestroy(data) {
  return request({ url: `/crossings/${data.id}/`, method: "delete", data });
}

export function crossingReset(data) {
  return request({ url: `/crossings/${data.id}/reset/`, method: "post", data });
}

export function crossingUnbind(data) {
  return request({ url: `/crossings/unbnind_con/`, method: "post", data });
}

// Con
export function conList(params) {
  return request({ url: `/cons/`, method: "get", params });
}

export function conDestroy(data) {
  return request({ url: `/cons/${data.id}/`, method: "delete", data });
}

export function conUploadFedex(data) {
  return request({ url: `/cons/${data.id}/upload_fedex/`, method: "post", data });
}

// Department
export function departmentList(params) {
  return request({ url: `/departments/`, method: "get", params });
}

export function departmentCreate(data) {
  return request({ url: `/departments/`, method: "post", data });
}

export function departmentUpdate(data) {
  return request({ url: `/departments/${data.id}/`, method: "put", data });
}

export function departmentDestroy(data) {
  return request({ url: `/departments/${data.id}/`, method: "delete", data });
}

// Airport
export function airportList(params) {
  return request({ url: `/airports/`, method: "get", params });
}

export function airportCreate(data) {
  return request({ url: `/airports/`, method: "post", data });
}

export function airportUpdate(data) {
  return request({ url: `/airports/${data.id}/`, method: "put", data });
}

export function airportDestroy(data) {
  return request({ url: `/airports/${data.id}/`, method: "delete", data });
}

// DestinationLocation
export function destinationLocationList(params) {
  return request({ url: `/destination_locations/`, method: "get", params });
}

export function destinationLocationCreate(data) {
  return request({ url: `/destination_locations/`, method: "post", data });
}

export function destinationLocationUpdate(data) {
  return request({ url: `/destination_locations/${data.id}/`, method: "put", data });
}

export function destinationLocationDestroy(data) {
  return request({ url: `/destination_locations/${data.id}/`, method: "delete", data });
}

export function destinationLocationExport(params) {
  return request({
    url: "/destination_locations/export_data/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function destinationLocationTemplate(params) {
  return request({
    url: "/destination_locations/download_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function destinationLocationImport(data) {
  return request({
    url: "/destination_locations/import_data/",
    method: "post",
    data,
  });
}

// Country
export function countryList(params) {
  return request({ url: `/countries/`, method: "get", params });
}

export function countryCreate(data) {
  return request({ url: `/countries/`, method: "post", data });
}

export function countryUpdate(data) {
  return request({ url: `/countries/${data.id}/`, method: "put", data });
}

export function countryDestroy(data) {
  return request({ url: `/countries/${data.id}/`, method: "delete", data });
}

// Residential
export function residentialList(params) {
  return request({ url: `/residentials/`, method: "get", params });
}

export function residentialCreate(data) {
  return request({ url: `/residentials/`, method: "post", data });
}

export function residentialUpdate(data) {
  return request({ url: `/residentials/${data.id}/`, method: "put", data });
}

export function residentialDestroy(data) {
  return request({ url: `/residentials/${data.id}/`, method: "delete", data });
}

// PostalCode
export function postalCodeList(params) {
  return request({ url: `/postal_codes/`, method: "get", params });
}

export function postalCodeCreate(data) {
  return request({ url: `/postal_codes/`, method: "post", data });
}

export function postalCodeUpdate(data) {
  return request({ url: `/postal_codes/${data.id}/`, method: "put", data });
}

export function postalCodeDestroy(data) {
  return request({ url: `/postal_codes/${data.id}/`, method: "delete", data });
}

export function postalCodeExport(params) {
  return request({
    url: "/postal_codes/export_data/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function postalCodeTemplate(params) {
  return request({
    url: "/postal_codes/download_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function postalCodeImport(data) {
  return request({
    url: "/postal_codes/import_data/",
    method: "post",
    data,
  });
}

// Sensitive
export function sensitiveList(params) {
  return request({ url: `/sensitives/`, method: "get", params });
}

export function sensitiveCreate(data) {
  return request({ url: `/sensitives/`, method: "post", data });
}

export function sensitiveUpdate(data) {
  return request({ url: `/sensitives/${data.id}/`, method: "put", data });
}

export function sensitiveDestroy(data) {
  return request({ url: `/sensitives/${data.id}/`, method: "delete", data });
}

// PackingCode
export function packingCodeList(params) {
  return request({ url: `/packing_codes/`, method: "get", params });
}

export function packingCodeCreate(data) {
  return request({ url: `/packing_codes/`, method: "post", data });
}

export function packingCodeUpdate(data) {
  return request({ url: `/packing_codes/${data.id}/`, method: "put", data });
}

export function packingCodeDestroy(data) {
  return request({ url: `/packing_codes/${data.id}/`, method: "delete", data });
}
